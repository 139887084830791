import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './style.css'
import { Button } from 'react-bootstrap';
import ShowRoomService from '../../services/ShowRoomService';

const Branches = () => {
  const [data, setData] = useState([])
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const {t} = useTranslation()
  const lang = useSelector(state => state?.lang?.lang)
  const showRoomService = new ShowRoomService()

  useEffect(()=>{
    setLoader(true)
    showRoomService?.getList().then(res=>{
      if(res?.status === 200){
        let info = res?.data?.data?.data
        setData(info)
      }
      setLoader(false)
    }).catch(()=> setLoader(false))
  },[])

  return <div className='branches-section'>
        <h5 className='type-title'>
          <span>Branches</span>
          {/* <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M39.84 17.9004L26.8 30.9404C25.26 32.4804 22.74 32.4804 21.2 30.9404L8.16003 17.9004" stroke="#292D32" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          </svg> */}
        </h5>
        <div className='branches'>
          <div className='row'>
            {data?.map((branche, index)=>{
              return <div className='col-md-4 col-6 branche' key={index}>
              <div className='position-relative h-100 d-flex flex-column justify-content-between'>
                <img src={branche?.image} alt={branche?.name} className='img w-100 h-100' />
                <div className='details'>
                  <p className='title'>{branche.name}</p>
                  <p className='address'>{branche.location}</p>
                  <p className='days'>{branche.day}</p>
                  <p className='times'>{branche.time}</p>
                  <p className='phone'>Tel: {branche.tel1}</p>
                  <p className='phone'>Tel: {branche.tel2}</p>
                  <a href={branche?.location_url} target='_blank' className='location' rel="noreferrer">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.488 22.5065V10.5025H1.5L22.5 1.5L13.488 22.5065Z" stroke="#F27405" stroke-width="1.8" stroke-linejoin="round"/>
                    </svg>
    
                    Location
                  </a>
                </div>
              </div>
            </div>
            })}
          </div>
        </div>
  </div>
};

export default Branches;
