import { API_BASE_URL_ENV } from "../common/common";
import http from './HttpService'
import BaseService from "./BaseService";

const apiEndpoint = API_BASE_URL_ENV() + "/subCategories/getSubCategoryOfCategory";

export default class SubCategoriesService extends BaseService {
    constructor() {
        super(apiEndpoint);
    }

    getList(id) {
        return http.get(`${apiEndpoint}/${id}`);
    }
}
