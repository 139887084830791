import { useNavigate } from 'react-router-dom';
import './style.css'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import CartService from '../../services/CartService';
import { addToCart } from '../../store/actions/AuthActions';

const ProductCard = ({product, hasAddToCart}) => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const lang = useSelector(state => state?.lang?.lang)
    const user = useSelector(state => state?.user)
    const dispatch = useDispatch()
    const cartService = new CartService()

    const addCart = () => {
        let data = {
            products: [{
                dynamic_variant: [],
                amount: 1,
                product_id: product?.id
            }]
        }

        if(!!user?.user){
            cartService.create(data).then(res=>{
                if(res?.status === 201){
                    toast.success(t("Product Added To Cart"));
                    dispatch(addToCart({
                        ...product,
                        in_stock: product?.amount,
                        dynamicVariants: [],
                        amount: 1
                    }))
                }
            }).catch(e=> {
                if(e.response?.data?.message === "product_is_already_Exist_in_your_cart"){
                    let data ={
                        product_id: product.id,
                        amount: 1
                    }
                    cartService.update(data).then(res=>{
                        if(res?.status === 200){
                            toast.success(t("Product Added To Cart"));
                        }
                    })
                    return
                }
                toast.error(e.response?.data?.message?.replaceAll('_', ' '))
            })
        } else {
            toast.success(t("Product Added To Cart"));
            dispatch(addToCart({
                ...product,
                in_stock: product?.amount,
                dynamicVariants: [],
                amount: 1
            }))
        }
      }

    return <div className='product-card'>
        <img src={product.product_images[0]?.url} className='product-image' alt='product' />
        <div className='details'>
            <div>
                <h6 onClick={()=> navigate(`/products/product/${product?.id}`, { state:{ product }})}>{lang==='en' ? product.name_en : product.name_ar}</h6>
                <div className='prices'>
                    <p className="price">{(product.offer ? product.offerPrice : product?.price)?.toFixed(3)} {t("KWD")}</p>
                    {product.offer && <p className="old-price">{product.price?.toFixed(3) || ''} {t("KWD")}</p>}
                    {product.offer && <p className="discount">-{(product.price-product.offerPrice)?.toFixed(3)} {t("KWD")}</p>}
                </div>
            </div>
            <div>
                <p className='brand' style={{height: '21px'}}>
                    {product?.brand_id && t("Brand")}
                    {product?.brand_id && ':'}
                    {product?.brand_id && <span className='mx-2'>
                        {lang==='en' ? product.brand?.name_en : product.brand?.name_ar}
                    </span>}
                </p>
                {(hasAddToCart && product?.amount > 0) && <button onClick={addCart} className="btn add-cart btn-block">{t("Add To Cart")}</button>}
                {(product?.amount === 0) && <p className="text-danger text-center">{t("out of stock")}</p>}
            </div>
        </div>
    </div>
}
export default ProductCard