import React, { useEffect, useState } from 'react';
import './style.css'
import { Link } from 'react-router-dom';
import CategoriesService from '../../../services/CategoriesService';
import { useSelector } from 'react-redux';
import 'swiper/css'
import { useTranslation } from 'react-i18next';

const Categories = () => {
  const [data, setData] = useState([])
  const {t} = useTranslation()
  const lang = useSelector(state => state?.lang?.lang)
  const categoriesService = new CategoriesService()

  useEffect(()=>{
    let params = {
      offset: 0,
      limit: 8
    }
    categoriesService?.getList(params).then(res=>{
      if(res?.status === 200){
        let info = res?.data?.data?.data?.map(cat=>{
          return{
            ...cat
          }
        })
        setData(info)
      }
    }).catch(()=> {})
  },[])

  // function toTitleCase(str) {
  //   return str.toLowerCase().split(' ').map(word => {
  //       return word.charAt(0).toUpperCase() + word.slice(1);
  //   }).join(' ');
  // }

  return <div className='categories-home'>
      <h1 className='section-title'>{t("categories")}</h1>
      <div className='section-categories'>
        {data?.map((category,index)=> {
          return <Link to={`/category/${category.id}`} key={index} className='category'>
            <img src={category.image} alt='category' className='w-100' />
            <div className='information'>
              <h1 className='title'>{lang === 'en' ? category.name_en : category.name_ar}</h1>
              {/* <p className='number'>{category?.num} Product</p> */}
            </div>
            {/* <div className='showMore'>
              <span>
                {t("View All")}
              </span>
            </div> */}
          </Link>
        })}
      </div>
  </div>

};

export default Categories;
