import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import logo from '../../assets/logo.png'
import { useEffect, useState } from 'react'
import Authentication from '../Authentication'
import { useDispatch, useSelector } from 'react-redux'
import Dropdown from 'react-bootstrap/Dropdown';
import { Logout } from '../../services/AuthService'
import { useTranslation } from 'react-i18next'
import { changeLang } from '../../store/actions/LangActions'
import { ShowLogin } from '../../store/actions/AuthActions'
import CartService from '../../services/CartService'
import './style.css'

const Navbar = () =>{
  const [cart, setCart] = useState(0)
  const [isHome, setIsHome] = useState(false)
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector(state => state.user)
  const lang = useSelector(state => state.lang.lang)
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    dispatch(changeLang(lng))
  };

  useEffect(()=>{
    let pathname = location.pathname.slice(1)
    if(!pathname){
      setIsHome(true)
    } else {
      setIsHome(false)
    }
  }, [location])

  useEffect(()=> {
    if(!!user?.user){
      new CartService().getList().then(res=>{
          if(res?.status === 200){
            setCart(res.data?.data?.sub_carts?.length)
          }
          return
      }).catch((e) => {
        if(e?.response?.data?.message === "cart_not_Exist"){
          setCart(user?.cart?.length)
        }
      })
    } else {
      setCart(user?.cart?.length)
    }
  },[user, user?.user, user?.cart])

  return <div>
    <nav className="navbar navbar-expand-lg">
      <div className='row w-100 m-auto' style={{alignItems: 'center'}}>
        <div className='col-md-3 col-4 one nav-logo'>
          <Link to='/' className="navbar-brand logo m-0 p-0" >
            <img src={logo} alt='logo' width={97} height={90} />
          </Link>
        </div>

        <div className='col-md-7 two col-3'>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" style={{width: 'fit-content'}} id="navbarNavAltMarkup">
            <div className="navbar-nav">
              <NavLink to='/' style={{color: isHome ? '#fff' : '#000'}} className={({ isActive }) => isActive ? "active nav-link" : "nav-link"} aria-current="page" >{t('home')}</NavLink>
              <NavLink to='/products' style={{color: isHome ? '#fff' : '#000'}} className={({ isActive }) => isActive ? "active nav-link" : "nav-link"} aria-current="page">{t('products')}</NavLink>
              <NavLink to='/brands' style={{color: isHome ? '#fff' : '#000'}} className={({ isActive }) => isActive ? "active nav-link" : "nav-link"} aria-current="page">{t('brands')}</NavLink>
              <NavLink to='/branches  ' style={{color: isHome ? '#fff' : '#000'}} state={''} className={({ isActive }) => isActive ? "active nav-link" : "nav-link"} aria-current="page">{t("branches")}</NavLink>
              <NavLink to='/about-us' style={{color: isHome ? '#fff' : '#000'}} className={({ isActive }) => isActive ? "active nav-link" : "nav-link"} aria-current="page">{t('about-us')}</NavLink>
              <button 
                style={{color: isHome ? '#fff' : '#000'}}
                onClick={()=> changeLanguage(lang === 'en' ? 'ar' : 'en')}
                class="nav-link"
              >{lang === 'en' ?'اللغه العربيه': 'English'}</button>
            </div>
          </div>
        </div>
        
        <div className='col-md-2 three col-5 d-flex align-items-center' style={{justifyContent: 'end'}}>
          <Link to='/cart' className="nav-link p-0 d-flex position-relative" style={{margin: '0 16px', alignItems: 'center'}}>
            {isHome ? <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.2 8.66699H20.8C25.3334 8.66699 25.7867 10.787 26.0934 13.3737L27.2934 23.3737C27.68 26.6537 26.6667 29.3337 22 29.3337H10.0134C5.33337 29.3337 4.32003 26.6537 4.72003 23.3737L5.92004 13.3737C6.21338 10.787 6.6667 8.66699 11.2 8.66699Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10.6666 10.667V6.00033C10.6666 4.00033 12 2.66699 14 2.66699H18C20 2.66699 21.3333 4.00033 21.3333 6.00033V10.667" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M27.2133 22.707H10.6666" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg> : <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.2 8.66602H20.8C25.3334 8.66602 25.7867 10.786 26.0934 13.3727L27.2934 23.3727C27.68 26.6527 26.6667 29.3327 22 29.3327H10.0134C5.33337 29.3327 4.32003 26.6527 4.72003 23.3727L5.92004 13.3727C6.21338 10.786 6.6667 8.66602 11.2 8.66602Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10.6666 10.666V5.99935C10.6666 3.99935 12 2.66602 14 2.66602H18C20 2.66602 21.3333 3.99935 21.3333 5.99935V10.666" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M27.2133 22.707H10.6666" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>}
            {cart > 0 && <p className='cart-num'>{cart}</p>}
          </Link>
          {!!user?.user ?  <Dropdown>
          <Dropdown.Toggle id="dropdown-basic" className='p-0' style={{background: 'none', border: 'none', paddingRight: '0'}}>
            {!!user?.user?.avatar ? <img src={user?.user?.avatar} alt='user' height={32} width={32} style={{borderRadius: '50%'}}/> : 
            isHome ? <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 16.0003C19.6819 16.0003 22.6667 13.0156 22.6667 9.33366C22.6667 5.65176 19.6819 2.66699 16 2.66699C12.3181 2.66699 9.33337 5.65176 9.33337 9.33366C9.33337 13.0156 12.3181 16.0003 16 16.0003Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M27.4533 29.3333C27.4533 24.1733 22.32 20 16 20C9.67996 20 4.54663 24.1733 4.54663 29.3333" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg> : <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16 15.9993C19.6819 15.9993 22.6667 13.0146 22.6667 9.33268C22.6667 5.65078 19.6819 2.66602 16 2.66602C12.3181 2.66602 9.33337 5.65078 9.33337 9.33268C9.33337 13.0146 12.3181 15.9993 16 15.9993Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M27.4533 29.3333C27.4533 24.1733 22.32 20 16 20C9.67996 20 4.54663 24.1733 4.54663 29.3333" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item style={{color: '#000'}} onClick={()=> navigate('/profile')}>
              {t("Profile")}
            </Dropdown.Item>
            <Dropdown.Item style={{color: '#000'}} onClick={()=> Logout(user?.accessToken, dispatch, navigate, location?.pathname)}>
              {t("Logout")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> : isHome ? <div onClick={()=> dispatch(ShowLogin(true))}>
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 16.0003C19.6819 16.0003 22.6667 13.0156 22.6667 9.33366C22.6667 5.65176 19.6819 2.66699 16 2.66699C12.3181 2.66699 9.33337 5.65176 9.33337 9.33366C9.33337 13.0156 12.3181 16.0003 16 16.0003Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M27.4533 29.3333C27.4533 24.1733 22.32 20 16 20C9.67996 20 4.54663 24.1733 4.54663 29.3333" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div> : <div onClick={()=> dispatch(ShowLogin(true))}>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16 15.9993C19.6819 15.9993 22.6667 13.0146 22.6667 9.33268C22.6667 5.65078 19.6819 2.66602 16 2.66602C12.3181 2.66602 9.33337 5.65078 9.33337 9.33268C9.33337 13.0146 12.3181 15.9993 16 15.9993Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M27.4533 29.3333C27.4533 24.1733 22.32 20 16 20C9.67996 20 4.54663 24.1733 4.54663 29.3333" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>}
          {user?.showLogin && 
            <Authentication 
              modal={user?.showLogin} 
              setModal={()=> dispatch(ShowLogin(false))}
            />
          }
        </div>
      </div>
    </nav>
  </div>
}
export default Navbar