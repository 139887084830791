import Categories from "./Categories"
import Products from "./Products"
import Header from "./Header"
import Showrooms from "./Showrooms"

const Home = () => {
    return <>
        <Header />
        <Categories />
        <Showrooms />
        <Products />
    </>
}
export default Home