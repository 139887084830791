import React, { useEffect, useState } from 'react';
import './style.css'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ProductsService from '../../../services/ProductsService';
import Loader from '../../../common/Loader';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

const Products = () => {
  const [products, setProducts] = useState([])
  const tabs = ['Featured Products', "Best seller", 'Offers', 'New Arrivals']
  const [selectTab, setSelectTab] = useState('Featured Products')
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const {t} = useTranslation()

  const lang = useSelector(state => state?.lang?.lang)
  const productsService = new ProductsService()

  useEffect(()=>{
    let params ={
      offset: 0,
      limit: 20,
    }
    if(selectTab === "Best seller") params['bestSeller']= true
    if(selectTab === "Offers") params['offer']= true
    if(selectTab === "New Arrivals") params['newIn']= true

    setLoader(true)
    productsService?.getList(params).then(res=>{
      if(res?.status === 200){
        let info = res?.data?.data?.data?.slice(0,8)
        setProducts(info)
      }
      setLoader(false)
    }).catch(()=> setLoader(false))
  },[selectTab])

  return (<div className='products-section'>
    <h1 className='section-title'>{t("products")}</h1>
    <div className='tabs'>
    <Swiper
        slidesPerView={2.5}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        modules={[]}
        breakpoints={{
          640: {
            slidesPerView: 2.5,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: 6,
            spaceBetween: 15,
          },
        }}
        className="mySwiper"
      >
        {tabs?.map((tab, index) => {
            return <SwiperSlide key={index} className={`${tab === selectTab ? 'active' : ''} tab`}
            onClick={()=> setSelectTab(tab)}>
              <span>{t(tab)}</span>
            </SwiperSlide>
          })}
      </Swiper>
      </div>
    <div>
      {!!products?.length ? <Swiper
        slidesPerView={1.5}
        spaceBetween={20}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 25,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        }}
        className="mySwiper"
      >
        {products?.map((item, index) => {
          return <SwiperSlide key={index} onClick={()=> navigate(`/products/product/${item?.id}`)} className='product-card'>
            <img src={!!item?.product_images?.length ? item?.product_images[0]?.url : ''} alt='product' className='w-100'/>
            <p className='title'>{lang ==='en' ? item?.name_en : item?.name_ar}</p>
            <p className='price'>{item?.price} {t("KWD")}</p>
          </SwiperSlide>
        })}
      </Swiper> : <h3 className='pb-5'>{t(`There's no Product`)}</h3>}
    </div>
    </div>
  );
};

export default Products;
