import { API_BASE_URL_ENV } from "../common/common";
import BaseService from "./BaseService";


const apiEndpoint = API_BASE_URL_ENV() + "/brands";

export default class BrandsService extends BaseService {
    constructor() {
        super(apiEndpoint);
    }
}
