import { useEffect, useState } from 'react'
import './style.css'
import trash from '../../assets/trash.svg'
import { useDispatch, useSelector } from 'react-redux'
import {  Button, Card, CardBody } from 'react-bootstrap'
import { decreaseProduct, increaseProduct, removeProduct } from '../../store/actions/AuthActions'
import { useTranslation } from 'react-i18next'
import CheckLogin from './CheckLogin'
import CartService from '../../services/CartService'
import Loader from '../../common/Loader'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

const Cart = () =>{
    const [cartProducts, setCartProducts] = useState([])
    const [shouldUpdate, setShouldUpdate] = useState(false)
    const [totalPrice, setTotalPrice] = useState(0)
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {t} = useTranslation()
    const user = useSelector(state => state?.user)
    const lang = useSelector(state => state?.lang?.lang)
    const cart = useSelector(state => state?.user?.cart)
    const cartService = new CartService()

    useEffect(()=>{
        setLoading(true)
        if(!!user?.user){
            cartService.getList().then(res=>{
                if(res?.status === 200){
                    let data = res?.data.data
                    setCartProducts(data?.sub_carts)
                    setTotalPrice(data?.sub_total.toFixed(3))
                }
                setLoading(false)
            }).catch(() => setLoading(false))
        } else {
            let data = cart?.map(item=> {
                return {
                    amount: item?.amount,
                    dynamicVariants: item?.dynamicVariants?.filter(res=> res?.amount > 0),
                    product: item
                }
            })
            setLoading(false)
            let totalP = data?.map(res=> {
                let tot = res?.product.offer ? (res?.product?.amount*res?.product?.offerPrice) + res?.dynamicVariants?.map(dy=> dy?.amount*dy?.price).reduce((accumulator, currentValue) => {
                    return accumulator + currentValue;
                }, 0) : (res?.product?.amount*res?.product?.price) + res?.dynamicVariants?.map(dy=> dy?.amount*dy?.price).reduce((accumulator, currentValue) => {
                    return accumulator + currentValue;
                }, 0)
                return tot
            }).reduce((accumulator, currentValue) => {
                return accumulator + currentValue;
            }, 0)
            setTotalPrice(totalP.toFixed(3))
            setCartProducts(data)
        }
    },[shouldUpdate, user?.user, user?.cart])

    const removeProductFromCart = (product) => {
        let data ={
            product_id: product.id
        }
        if(!!user?.user){
            cartService.remove(data).then(res=>{
                if(res?.status === 200){
                    toast.success(t("Remove from Cart"))
                    setShouldUpdate(prev=> !prev)
                }
            })
        }
        dispatch(removeProduct(product))
    }

    const changeAmount = (product, amount) => {
        let data ={
            product_id: product.id,
            amount: amount
        }
        cartService.update(data).then(res=>{
            if(res?.status === 200){
                setShouldUpdate(prev=> !prev)
            }
        })
    }

    if(loading){
        return <div className='d-flex align-items-center justify-content-around' style={{minHeight: '80vh'}}>
            <Loader />
            </div>
    }

    return <div className="cart">
        <div className='container'>
            {cartProducts?.length > 0 ? <div className='row'>
                <div className='col-md-8'>
                    <Card style={{border: 'none', boxShadow: '0 0 4px rgba(222, 222, 222, 0.47)'}}>
                        <CardBody style={{background: '#F8F8F8'}}>
                            {cartProducts?.map(product => {
                            return <div key={product?.product?.id} className='product-cart'>
                                    <div className='row'>
                                        <div className='col-md-9 col-12 d-flex' style={{gap: '16px'}}>
                                            <div>
                                                <img src={!!product?.product?.product_images?.length && product?.product?.product_images[0]?.url} alt='img' width={90} height={90} />
                                                <div className='text-center mt-3'>
                                                    <button className='trash' onClick={()=> removeProductFromCart(product?.product)}>
                                                        <img src={trash} alt='trash' /> {t("Delete")}
                                                    </button>
                                                </div>
                                            </div>
                                            <div>
                                                <h4 className='product-name m-0'>{lang === 'en' ? product?.product?.name_en : product?.product?.name_ar}</h4>
                                                <h5 className='product-amount mb-2'>{product?.amount} * {(product?.product?.offer ? product?.product?.offerPrice : product?.product?.price).toFixed(3)}</h5>
                                                {!!user?.user ? product?.dynamic_variant_carts?.map((dy,ind) => {
                                                    return <h6 key={ind}>
                                                        {lang === 'en' ? dy?.dynamic_variant?.name_en : dy?.dynamic_variant?.name_ar} ({dy?.amount} * {dy?.dynamic_variant?.price?.toFixed(3)})
                                                    </h6>
                                                }) : product?.dynamicVariants?.map((dy,ind) => {
                                                    return <h6 key={ind}>
                                                        {lang === 'en' ? dy?.name_en : dy?.name_ar} ({dy?.amount} * {dy?.price?.toFixed(3)})
                                                    </h6>
                                                })}
                                            </div>
                                        </div>
                                        <div className='col-md-3 col-12'>
                                            <div style={{textAlign: lang === 'en' ? 'right' : 'left'}}>
                                               {!user?.user && (product?.dynamicVariants?.length ? <h4 style={{dontSize: '18px'}} className='mb-3 text-primary'>{((product?.amount*(product?.product?.offer ? product?.product?.offerPrice : product?.product?.price)) + product?.dynamicVariants?.map(res=> res?.amount*res?.price).reduce((accumulator, currentValue) => {
                                                    return accumulator + currentValue;
                                                }, 0)).toFixed(3)} {t("KWD")}</h4> :
                                                <h4 style={{dontSize: '18px'}} className='mb-3 text-primary'>{(product?.amount*(product?.product?.offer ? product?.product?.offerPrice : product?.product?.price)).toFixed(3)} {t("KWD")}</h4>)}
                                               
                                               {!!user?.user && (product?.dynamic_variant_carts?.length ? <h4 className='mb-3 text-primary'>{((product?.amount*(product?.product?.offer ? product?.product?.offerPrice : product?.product?.price)) + product?.dynamic_variant_carts?.map(res=> res?.amount*res?.dynamic_variant?.price).reduce((accumulator, currentValue) => {
                                                    return accumulator + currentValue;
                                                }, 0)).toFixed(3)} {t("KWD")}</h4> :
                                                <h4 style={{dontSize: '18px'}} className='mb-3 text-primary'>{(product?.amount*(product?.product?.offer ? product?.product?.offerPrice : product?.product?.price)).toFixed(3)} {t("KWD")}</h4>)}
                                            </div>
                                            {product?.product?.offer && <h6 style={{textDecoration: 'line-through', textAlign: lang === 'en' ? 'right' : 'left'}} className='mb-3'>{(Number(product?.amount)*Number(product?.product?.price)).toFixed(3)} {t("KWD")}</h6>}
                                            <div style={{textAlign: lang === 'en' ? 'right' : 'left'}}>
                                                <button 
                                                    className='prod-btn minus' 
                                                    disabled={product?.amount === 1} 
                                                    style={{cursor: product?.amount > 1 ? 'pointer' : 'not-allowed'}}
                                                    onClick={()=> {
                                                        if(!!user?.user){
                                                            changeAmount(product?.product, product?.amount-1)
                                                        }
                                                        dispatch(decreaseProduct(product?.product))
                                                    }}
                                                >
                                                    -
                                                </button>
                                                <span style={{fontSize: '20px'}} className='mx-3'>{product?.amount}</span>
                                                <button 
                                                    className='prod-btn' 
                                                    disabled={product?.amount === product?.product?.amount} 
                                                    style={{cursor: product?.amount === product?.product?.amount ? 'not-allowed' : 'pointer'} }
                                                    onClick={()=> {
                                                    if(!!user?.user){
                                                        changeAmount(product?.product, product?.amount+1)
                                                    }
                                                    dispatch(increaseProduct(product?.product))
                                                } }>
                                                    +
                                                </button>
                                            </div>
                                            
                                        </div>
                                    </div>
                            </div>
                            })}
                        </CardBody>
                    </Card>
                </div>
                <div className='col-md-4'>
                    <Card className='payment-details' style={{border: 'none',boxShadow: '0 0 12px #dedede78'}}>
                        <CardBody>
                            <h5 className='mb-4'>{t("Payment Details")}</h5>

                            <div className='d-flex justify-content-between'>
                                <h5 style={{fontSize: '18px'}}>{t("Total Price")}:</h5>
                                <h5 style={{fontSize: '18px', fontWeight: "600", color: '#000'}}>{totalPrice} {t("KWD")}</h5>
                            </div>
                            <div>
                                <button 
                                    className='continue w-100'
                                    onClick={()=> {
                                        if(!user?.user){
                                            setModal(true)
                                        }else{
                                            navigate('/checkout')
                                        }
                                    }}
                                >{t("Checkout")}</button>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </div> : <div className='text-center' style={{marginTop: '160px', padding: '34px 0', marginBottom: '100px', background: '#F8F8F8'}}>
                <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M70 140C108.66 140 140 108.66 140 70C140 31.3401 108.66 0 70 0C31.3401 0 0 31.3401 0 70C0 108.66 31.3401 140 70 140Z" fill="#D0D0D0"/>
                    <g opacity="0.2">
                    <path d="M107.188 56.875H32.8125C29.1878 56.875 26.25 59.8128 26.25 63.4375L35 102.812C35 106.437 37.9378 109.375 41.5625 109.375H98.4375C102.062 109.375 105 106.437 105 102.812L113.75 63.4375C113.75 59.8128 110.812 56.875 107.188 56.875ZM52.5 98.4375C52.5 100.855 50.5422 102.812 48.125 102.812C45.7078 102.812 43.75 100.855 43.75 98.4375V85.3125C43.75 82.8953 45.7078 80.9375 48.125 80.9375C50.5422 80.9375 52.5 82.8953 52.5 85.3125V98.4375ZM74.375 98.4375C74.375 100.855 72.4172 102.812 70 102.812C67.5828 102.812 65.625 100.855 65.625 98.4375V85.3125C65.625 82.8953 67.5828 80.9375 70 80.9375C72.4172 80.9375 74.375 82.8953 74.375 85.3125V98.4375ZM96.25 98.4375C96.25 100.855 94.2922 102.812 91.875 102.812C89.4578 102.812 87.5 100.855 87.5 98.4375V85.3125C87.5 82.8953 89.4578 80.9375 91.875 80.9375C94.2922 80.9375 96.25 82.8953 96.25 85.3125V98.4375Z" fill="#231F20"/>
                    </g>
                    <path d="M107.188 52.5H32.8125C29.1878 52.5 26.25 55.4378 26.25 59.0625L35 98.4375C35 102.062 37.9378 105 41.5625 105H98.4375C102.062 105 105 102.062 105 98.4375L113.75 59.0625C113.75 55.4378 110.812 52.5 107.188 52.5ZM52.5 94.0625C52.5 96.4797 50.5422 98.4375 48.125 98.4375C45.7078 98.4375 43.75 96.4797 43.75 94.0625V80.9375C43.75 78.5203 45.7078 76.5625 48.125 76.5625C50.5422 76.5625 52.5 78.5203 52.5 80.9375V94.0625ZM74.375 94.0625C74.375 96.4797 72.4172 98.4375 70 98.4375C67.5828 98.4375 65.625 96.4797 65.625 94.0625V80.9375C65.625 78.5203 67.5828 76.5625 70 76.5625C72.4172 76.5625 74.375 78.5203 74.375 80.9375V94.0625ZM96.25 94.0625C96.25 96.4797 94.2922 98.4375 91.875 98.4375C89.4578 98.4375 87.5 96.4797 87.5 94.0625V80.9375C87.5 78.5203 89.4578 76.5625 91.875 76.5625C94.2922 76.5625 96.25 78.5203 96.25 80.9375V94.0625Z" fill="#F27405"/>
                    <g opacity="0.2">
                    <path d="M122.5 61.25C122.5 63.6672 120.542 65.625 118.125 65.625H21.875C19.4578 65.625 17.5 63.6672 17.5 61.25C17.5 58.8328 19.4578 56.875 21.875 56.875H118.125C120.542 56.875 122.5 58.8328 122.5 61.25Z" fill="#231F20"/>
                    </g>
                    <path d="M122.5 56.875C122.5 59.2922 120.542 61.25 118.125 61.25H21.875C19.4578 61.25 17.5 59.2922 17.5 56.875C17.5 54.4578 19.4578 52.5 21.875 52.5H118.125C120.542 52.5 122.5 54.4578 122.5 56.875Z" fill="#F27405"/>
                    <g opacity="0.2">
                    <path d="M93.6867 33.1895C95.3951 34.898 95.3951 37.6673 93.6867 39.3758L68.7186 73.0939C67.0101 74.8023 64.2407 74.8023 62.5323 73.0939C60.8239 71.3855 60.8239 68.6161 62.5323 66.9077L87.5004 33.1895C89.2089 31.4811 91.9782 31.4811 93.6867 33.1895Z" fill="#231F20"/>
                    </g>
                    <path d="M93.6867 28.8145C95.3951 30.523 95.3951 33.2923 93.6867 35.0008L68.7186 68.7189C67.0101 70.4273 64.2407 70.4273 62.5323 68.7189C60.8239 67.0105 60.8239 64.2411 62.5323 62.5327L87.5004 28.8145C89.2089 27.1061 91.9782 27.1061 93.6867 28.8145Z" fill="#4F5D73"/>
                </svg>

                <h3 className='mt-4' style={{fontSize: '18px', fontWeight: '500'}}>Your cart is empty!</h3>
                <h4 className='mt-2' style={{fontSize: '16px', fontWeight: '400', color:'#7D7F80'}}>Browse our categories and discover our best deals!</h4>
                <Button 
                    variant='primary'
                    onClick={()=> navigate('/products')}
                    style={{
                        width: '370px',
                        borderRadius: '10px',
                        height: '37px',
                        marginTop: '24px'
                    }}
                >Start Shopping</Button>
            </div>}
        </div>
        {modal && <CheckLogin modal={modal} setModal={()=> setModal(false)} />}
    </div>
}
export default Cart