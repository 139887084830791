import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MHSocialMediaService from "../../services/MHSocialMediaService";   
import logo from '../../assets/logo.png'
import knet from '../../assets/knet.svg'
import visa from '../../assets/visa.svg'
import mastercard from '../../assets/mastercard.svg'

import './style.css'

const Footer = () => {
    const [data, setData] = useState({
        facebook: '',
        instagram: '',
        twitter: '',
        you_tube: '',
        snapchat: '',
        whatsapp: '',
        email: '',
    })
    const {t} = useTranslation()
    const mHSocialMediaService = new MHSocialMediaService()

    useEffect(()=>{
        mHSocialMediaService.getList().then(res=>{
            if(res?.status === 200){
                setData(res?.data?.data)
            }
        })
    },[])

    return <div className="footer">
        <div>
            <div className="default-section row">
                <div className="col-md-3 one col-12">
                    <h4 className="contacts-title">{t("Contacts")}</h4>
                    <div className="contacts">
                        {!!data?.call_us && <p>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.05 14.95L9.2 16.8C8.81 17.19 8.19 17.19 7.79 16.81C7.68 16.7 7.57 16.6 7.46 16.49C6.43 15.45 5.5 14.36 4.67 13.22C3.85 12.08 3.19 10.94 2.71 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C10.83 13.32 10.94 13.42 11.04 13.52C11.44 13.91 11.45 14.55 11.05 14.95Z" fill="#F27405"/>
                                <path d="M21.9701 18.3291C21.9701 18.6091 21.9201 18.8991 21.8201 19.1791C21.7901 19.2591 21.7601 19.3391 21.7201 19.4191C21.5501 19.7791 21.3301 20.1191 21.0401 20.4391C20.5501 20.9791 20.0101 21.3691 19.4001 21.6191C19.3901 21.6191 19.3801 21.6291 19.3701 21.6291C18.7801 21.8691 18.1401 21.9991 17.4501 21.9991C16.4301 21.9991 15.3401 21.7591 14.1901 21.2691C13.0401 20.7791 11.8901 20.1191 10.7501 19.2891C10.3601 18.9991 9.9701 18.7091 9.6001 18.3991L12.8701 15.1291C13.1501 15.3391 13.4001 15.4991 13.6101 15.6091C13.6601 15.6291 13.7201 15.6591 13.7901 15.6891C13.8701 15.7191 13.9501 15.7291 14.0401 15.7291C14.2101 15.7291 14.3401 15.6691 14.4501 15.5591L15.2101 14.8091C15.4601 14.5591 15.7001 14.3691 15.9301 14.2491C16.1601 14.1091 16.3901 14.0391 16.6401 14.0391C16.8301 14.0391 17.0301 14.0791 17.2501 14.1691C17.4701 14.2591 17.7001 14.3891 17.9501 14.5591L21.2601 16.9091C21.5201 17.0891 21.7001 17.2991 21.8101 17.5491C21.9101 17.7991 21.9701 18.0491 21.9701 18.3291Z" fill="#F27405"/>
                            </svg>
                            {data?.call_us}
                        </p>}
                        <p>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.35 15.57C16.21 15.81 15.96 15.94 15.7 15.94C15.57 15.94 15.44 15.91 15.32 15.83L12.22 13.98C11.45 13.52 10.88 12.51 10.88 11.62V7.52C10.88 7.11 11.22 6.77 11.63 6.77C12.04 6.77 12.38 7.11 12.38 7.52V11.62C12.38 11.98 12.68 12.51 12.99 12.69L16.09 14.54C16.45 14.75 16.57 15.21 16.35 15.57Z" fill="#F27405"/>
                        </svg>
                        {t("Working Hours")}: {data?.time_from} - {data?.time_to}
                        </p>
                        <p>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.35 15.57C16.21 15.81 15.96 15.94 15.7 15.94C15.57 15.94 15.44 15.91 15.32 15.83L12.22 13.98C11.45 13.52 10.88 12.51 10.88 11.62V7.52C10.88 7.11 11.22 6.77 11.63 6.77C12.04 6.77 12.38 7.11 12.38 7.52V11.62C12.38 11.98 12.68 12.51 12.99 12.69L16.09 14.54C16.45 14.75 16.57 15.21 16.35 15.57Z" fill="#F27405"/>
                        </svg>
                        {t("Working Days")}: {data?.day_from} - {data?.day_to}
                        </p>
                        {!!data?.gmail && <p>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 3.5H7C4 3.5 2 5 2 8.5V15.5C2 19 4 20.5 7 20.5H17C20 20.5 22 19 22 15.5V8.5C22 5 20 3.5 17 3.5ZM17.47 9.59L14.34 12.09C13.68 12.62 12.84 12.88 12 12.88C11.16 12.88 10.31 12.62 9.66 12.09L6.53 9.59C6.21 9.33 6.16 8.85 6.41 8.53C6.67 8.21 7.14 8.15 7.46 8.41L10.59 10.91C11.35 11.52 12.64 11.52 13.4 10.91L16.53 8.41C16.85 8.15 17.33 8.2 17.58 8.53C17.84 8.85 17.79 9.33 17.47 9.59Z" fill="#F27405"/>
                        </svg>
                        {data?.gmail}
                        </p>}
                        <p>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.6201 8.45C19.5701 3.83 15.5401 1.75 12.0001 1.75C12.0001 1.75 12.0001 1.75 11.9901 1.75C8.4601 1.75 4.4201 3.82 3.3701 8.44C2.2001 13.6 5.3601 17.97 8.2201 20.72C9.2801 21.74 10.6401 22.25 12.0001 22.25C13.3601 22.25 14.7201 21.74 15.7701 20.72C18.6301 17.97 21.7901 13.61 20.6201 8.45ZM12.0001 13.46C10.2601 13.46 8.8501 12.05 8.8501 10.31C8.8501 8.57 10.2601 7.16 12.0001 7.16C13.7401 7.16 15.1501 8.57 15.1501 10.31C15.1501 12.05 13.7401 13.46 12.0001 13.46Z" fill="#F27405"/>
                        </svg>
                        <Link to='/branches'>
                            {t("Show Rooms")}
                        </Link>
                        </p>
                    </div>
                </div>
                <div className="col-md-6 two col-12">
                    <div className="text-center description">
                        <img src={logo} alt='logo' width={95} height={92} />
                        <p>
                            Synonym with quality and reliability, Al Shamali and Waris Electrical Tools & Contracting Company K.S.C.C is a Kuwait based renowned electrical Trading Contracting and Metal Fabrication Company since the last four decades.
                            Incepted in 1976, We started our operation as a small-scale supplier of electrical products. Since its inception the company has grown into one of the leading Electrical Trading Companies in Kuwait and UAE.
                            With multiple outlets strategically located namely three showrooms in Shuwaikh, One in Darwaza Abdul Razak, One in Ahmedi near KOC, KNPC Headquarters, Warehouse and showroom in Ardiya, Showroom in Al Rai & Metal Fabrication Facility in Shuaiba.
                        </p>
                    </div>
                </div>
                <div className="col-md-3 three col-12">
                    <div className="social d-flex flex-column  justify-content-end">
                            <div>
                                <p className="follow">{t("Follow us")}</p>
                                <div className="icons ">
                                    {!!data.facebook && <a rel="noreferrer" href={data.facebook} target='_blank'>
                                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_360_4142)">
                                            <path d="M20 40C8.956 40 0 31.044 0 20C0 8.956 8.956 0 20 0C31.044 0 40 8.956 40 20C40 31.044 31.044 40 20 40Z" fill="#F27405"/>
                                            <path d="M25.2 40.0009V24.5089H30.4L31.18 18.4729H25.2V14.6169C25.2 12.8689 25.684 11.6769 28.192 11.6769H31.388V6.28092C30.836 6.20892 28.936 6.04492 26.728 6.04492C22.12 6.04492 18.964 8.85692 18.964 14.0249V18.4769H13.752V24.5129H18.964V40.0009H25.2Z" fill="white"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_360_4142">
                                            <rect width="40" height="40" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>
                                    </a>}
                                    {!!data?.instagram && <a rel="noreferrer" href={data?.instagram} target='_blank'>
                                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_360_4149)">
                                        <path d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z" fill="#F27405"/>
                                        <path d="M25.7329 15.536C26.4477 15.536 27.0272 14.9565 27.0272 14.2416C27.0272 13.5268 26.4477 12.9473 25.7329 12.9473C25.018 12.9473 24.4385 13.5268 24.4385 14.2416C24.4385 14.9565 25.018 15.536 25.7329 15.536Z" fill="white"/>
                                        <path d="M19.9995 24.7431C22.6188 24.7431 24.7421 22.6198 24.7421 20.0005C24.7421 17.3812 22.6188 15.2578 19.9995 15.2578C17.3802 15.2578 15.2568 17.3812 15.2568 20.0005C15.2568 22.6198 17.3802 24.7431 19.9995 24.7431Z" stroke="white" stroke-width="1.75781" stroke-miterlimit="10"/>
                                        <path d="M9.97461 23.9262C9.97461 27.4859 12.8603 30.3715 16.4199 30.3715H23.5794C27.1391 30.3715 30.0247 27.4859 30.0247 23.9262V16.0742C30.0247 12.5146 27.1391 9.62891 23.5794 9.62891H16.4199C12.8603 9.62891 9.97461 12.5146 9.97461 16.0742V23.9262Z" stroke="white" stroke-width="1.75781" stroke-miterlimit="10"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_360_4149">
                                        <rect width="40" height="40" fill="white"/>
                                        </clipPath>
                                        </defs>
                                        </svg>
                                    </a>}
                                    {!!data?.whatsapp && <a rel="noreferrer" href={data?.whatsapp} target='_blank'>
                                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="40" height="40" rx="20" fill="#F27405"/>
                                        <path d="M21.2188 9C15.2531 9 10.4375 13.8156 10.4375 19.7812C10.4375 22.225 11.2281 24.525 12.7375 26.3938L11.2281 30.9937C11.1562 31.2812 11.2281 31.5688 11.4438 31.7844C11.5156 31.9281 11.7313 32 11.875 32C11.9469 32 12.0906 32 12.1625 31.9281L17.1219 29.7C18.4156 30.275 19.7812 30.5625 21.2188 30.5625C27.1844 30.5625 32 25.7469 32 19.7812C32 13.8156 27.1844 9 21.2188 9ZM27.4719 23.7344C27.1844 24.5969 26.1062 25.3156 25.1719 25.4594C24.9563 25.5312 24.7406 25.5312 24.4531 25.5312C23.8781 25.5312 23.0156 25.3875 21.5062 24.7406C19.7812 24.0219 18.0562 22.5125 16.6906 20.5719V20.5C16.2594 19.8531 15.4688 18.6313 15.4688 17.3375C15.4688 15.7563 16.2594 14.9656 16.5469 14.6063C16.9062 14.2469 17.4094 14.0312 17.9844 14.0312C18.1281 14.0312 18.2 14.0312 18.3438 14.0312C18.8469 14.0312 19.2063 14.175 19.5656 14.8937L19.8531 15.4688C20.0688 16.0437 20.3562 16.6906 20.4281 16.7625C20.6438 17.1938 20.6438 17.5531 20.4281 17.9125C20.3562 18.1281 20.2125 18.2719 20.0687 18.4156C19.9969 18.5594 19.925 18.6312 19.8531 18.6312C19.7812 18.7031 19.7812 18.7031 19.7094 18.775C19.925 19.1344 20.3562 19.7812 20.9313 20.2844C21.7938 21.075 22.4406 21.2906 22.8 21.4344C22.9438 21.2906 23.0875 21.0031 23.3031 20.7875L23.375 20.6438C23.7344 20.1406 24.3094 19.9969 24.8844 20.2125C25.1719 20.3562 26.7531 21.075 26.7531 21.075L26.8969 21.1469C27.1125 21.2906 27.4 21.3625 27.5437 21.65C27.8312 22.2969 27.6156 23.2312 27.4719 23.7344Z" fill="white"/>
                                        </svg>

                                    </a>}
                                    {!!data.gmail && <a rel="noreferrer" href={`mailto: ${data.gmail}`} target='_blank'>
                                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z" fill="#F27405"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 14.5353V25.8604C10 26.4766 10.451 26.9759 11.0723 26.9759H28.7471C29.3677 26.9759 29.8194 26.4708 29.8194 25.8604V14.5353C29.8194 13.8591 29.4155 13.334 28.7471 13.334H11.0723C10.3781 13.334 10 13.872 10 14.5353ZM11.6297 15.5217C11.6297 15.2488 11.7948 15.0927 12.0587 15.0927C12.2219 15.0927 18.6445 19.1624 19.0335 19.4011L20.0387 20.0262C20.3574 19.8127 20.6774 19.6301 21.0077 19.4088C21.6819 18.9779 27.6806 15.0927 27.8458 15.0927C28.1103 15.0927 28.2748 15.2488 28.2748 15.5217C28.2748 15.8108 27.7181 16.0979 27.3555 16.3191C25.0774 17.7075 22.8 19.2211 20.5445 20.6611C20.4129 20.7501 20.1581 20.9404 19.9671 20.9114C19.7542 20.8785 13.2187 16.6798 12.0297 15.9804C11.851 15.8753 11.6297 15.7791 11.6297 15.5217Z" fill="white"/>
                                        </svg>

                                    </a>}
                                </div>
                                <hr />
                                <div className="links mb-4">
                                    <Link to='/about-us'>{t("about-us")}</Link>
                                    {/* <Link to='/'>Terms and Conditions</Link> */}
                                    {!!data?.address && <p className="address">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20.62 8.45C19.57 3.83 15.54 1.75 12 1.75C12 1.75 12 1.75 11.99 1.75C8.45997 1.75 4.41997 3.82 3.36997 8.44C2.19997 13.6 5.35997 17.97 8.21997 20.72C9.27997 21.74 10.64 22.25 12 22.25C13.36 22.25 14.72 21.74 15.77 20.72C18.63 17.97 21.79 13.61 20.62 8.45ZM12 13.46C10.26 13.46 8.84997 12.05 8.84997 10.31C8.84997 8.57 10.26 7.16 12 7.16C13.74 7.16 15.15 8.57 15.15 10.31C15.15 12.05 13.74 13.46 12 13.46Z" fill="#F27405"/>
                                        </svg>

                                        <span>
                                           {data?.address}
                                        </span>
                                    </p>}
                                    <div className="visa mb-3">
                                        <img src={visa} alt='visa' />
                                        <img src={mastercard} alt='mastercard' className="mx-2" />
                                        <img src={knet} alt='knet' />
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
                
            </div>
            <div>
                <p className="text-center power mb-0">{t("Powered By")}
                <a rel="noreferrer" 
                    href='https://www.cloudliftsolution.com/' 
                    target='_blank'
                    style={{
                        color: '#fff',
                        textDecoration: 'none',
                        margin: '0 2px'
                    }}
                >Cloud Lift Solutions</a>
                </p>
            </div>
        </div>
    </div>
}
export default Footer;