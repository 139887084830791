import React from 'react'
import { Link } from 'react-router-dom'
import ErrorImage from '../assets/404.png'

const Error404 = () => {
  return (
    <div className='error-page'>
      <div className='container'>
        <div className='form-input-content text-center'>
              <h1 className='text-primary'>404</h1>
              <h4>
                page not found!
              </h4>
              <img src={ErrorImage} alt='ErrorImage' />
              <div>
                <Link className='btn btn-primary' to='/'>
                  Try Again
                </Link>
              </div>
            </div>
      </div>
    </div>
  )
}

export default Error404
