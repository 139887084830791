import React, { useEffect, useState } from 'react';
import './style.css'
import { useTranslation } from 'react-i18next';
import Path from '../../common/Path';
import AboutService from '../../services/AboutService';
import { useSelector } from 'react-redux';

const AboutUs = () => {
  const {t} = useTranslation()
  const [about, setAbout] = useState([])
  const lang = useSelector(state => state?.lang?.lang)
  const aboutService = new AboutService()

  useEffect(()=>{
    aboutService?.getList({type: 'about'}).then(res=>{
      if(res?.status === 200){
        setAbout(res?.data?.data)
      }
    })
  }, [])

  return (<div className='aboutUs'>
        <h5 className='type-title'>
          <span>{t("about-us")}</span>
          {/* <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M39.84 17.9004L26.8 30.9404C25.26 32.4804 22.74 32.4804 21.2 30.9404L8.16003 17.9004" stroke="#292D32" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          </svg> */}
        </h5>
      <div>
        {about?.map((info,index)=>{
            return <div className="about-us" key={index}>
                <div className='w-50 description'>
                  <h2 className='mb-4'>{lang === 'en' ? info?.title_en : info?.title_ar}</h2>
                  <p dangerouslySetInnerHTML={{ __html: lang === 'en' ? info?.description_en : info?.description_ar }}></p>
              </div>
              <div className='w-50 image'>
                <img src={info?.image} className='w-100' alt='about' />
              </div>
            </div>
        })}
      </div>
    </div>
  );
};

export default AboutUs;
