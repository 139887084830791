import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './style.css'
import { Button } from 'react-bootstrap';
import BrandsService from '../../services/BrandsService';

const Brands = () => {
  const [search, setSearch] = useState('')
  const [data, setData] = useState([
    {
      id: 1,
      name: 'Corporate Office',
      image: 'https://via.placeholder.com/250',
    },
    {
      id: 2,
      name: 'Corporate Office',
      image: 'https://via.placeholder.com/250',
    },
    {
      id: 3,
      name: 'Corporate Office',
      image: 'https://via.placeholder.com/250',
    },
    {
      id: 4,
      name: 'Corporate Office',
      image: 'https://via.placeholder.com/250',
    },
    {
      id: 5,
      name: 'Corporate Office',
      image: 'https://via.placeholder.com/250',
    },
    {
      id: 6,
      name: 'Corporate Office',
      image: 'https://via.placeholder.com/250',
    },
  ])

  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const {t} = useTranslation()
  const lang = useSelector(state => state?.lang?.lang)
  // const categoriesService = new CategoriesService()

  useEffect(()=>{
    setLoader(true)
    let params = {}
    if(!!search) params['search'] = search
    new BrandsService()?.getList(params).then(res=>{
      if(res?.status === 200){
        let info = res?.data?.data?.data
        setData(info)
      }
      setLoader(false)
    }).catch(()=> setLoader(false))
  },[search])

  return <div className='brands-section'>
        <h5 className='type-title'>
          <span>{t("Brands")}</span>
          {/* <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M39.84 17.9004L26.8 30.9404C25.26 32.4804 22.74 32.4804 21.2 30.9404L8.16003 17.9004" stroke="#292D32" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          </svg> */}
        </h5>
        <div className='brands'>
          <div className='row'>
            {data?.map((brand, index)=>{
              return <div className="col-md-3 brand col-6" key={index}>
                <div className='brand-card'>
                  <img src={brand.image} alt='brand' className='brand-image w-100' />
                  <div>
                    <p>{lang === 'en' ? brand?.name_en : brand?.name_ar}</p>
                    <button onClick={()=> navigate(`/brand/${brand.id}`)} className='more-details'>{t("more Details")}</button>
                  </div>
                </div>
              </div>
            })}
          </div>
        </div>
  </div>
};

export default Brands;
