import { API_BASE_URL_ENV } from "../common/common";
import http from './HttpService'
import BaseService from "./BaseService";

const apiEndpoint = API_BASE_URL_ENV() + "/showRoom";
const apiBannerEndpoint = API_BASE_URL_ENV() + "/banner/showRoom";

export default class ShowRoomService extends BaseService {
  constructor() {
    super(apiEndpoint);
  }

  banner(data) {
    return http.get(apiBannerEndpoint, data);
  }
}
